import React, { useState, useEffect,useCallback } from 'react';
import { StyleSheet,View,Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl,ScrollView,SafeAreaView , Linking} from 'react-native';
import Modal from 'react-native-modal';

import Contact from '../components/contact'
import colours from '../config/colours';
import { useNavigation } from '@react-navigation/native';


const { height, width } = Dimensions.get('window')

if(height<width){
  var widthNew=height;
  var heightNew=width;
} else{
  var widthNew=width;
  var heightNew=height;
}


const Footer = () => {
  const [isModalVisibleContact, setModalVisibleContact] = useState(false);

  const toggleModalContact = () => {
    setModalVisibleContact(!isModalVisibleContact);
  };
    
    const goHomeContact = () => {
      setModalVisibleContact(!isModalVisibleContact);
    };

    const navigation = useNavigation();
    return (
      <SafeAreaView>
      <View style={styles.safeView}>
      <View style={styles.responsiveWidth}>
      <View style={styles.collectionsContainer}>
              <View style={styles.contactContainer}>
                <View style={styles.categoryTitle}>
                    <Text numberOfLines={1} style={styles.categoryTitleText} >
                     Get in Touch   
                    </Text>
                </View>

                <View style={styles.categorySeeAll}>
                <TouchableOpacity onPress={() => toggleModalContact() }>
                    <Text numberOfLines={1} style={styles.categorySeeAllText} >
                      Contact Us 
                    </Text>
                </TouchableOpacity>
                </View>

              </View>
              
            </View>

            <View style={styles.footer}>
                    <Text numberOfLines={1} style={styles.footerText} >
                        © Copyright {new Date().getFullYear()} | PDC GROUP | All rights reserved
                    </Text>
                </View> 

<Modal 
isVisible={isModalVisibleContact} 
transparent={true}
onBackdropPress={goHomeContact}
>
<View>
  <Contact
  toggleModal={toggleModalContact}
  />
</View>
</Modal>

      </View>
      </View>
      </SafeAreaView>

    );
  };



const styles = StyleSheet.create({
  safeView: {
    flex: 1,
    backgroundColor: colours.primaryBlack,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems:'center'
  },

  responsiveWidth: {
   // width:widthNew,
   width: width>height? (width *.70) : widthNew,
   alignContent: 'center',
    justifyContent: 'center',
  },

  collectionsContainer: {
    marginHorizontal: 50,
    backgroundColor: colours.firstGrey,
    borderRadius: 20,
    marginVertical: 50,
    paddingVertical: 10,
  },
  contactContainer: {
    flexDirection: 'row',
    marginHorizontal: (widthNew / 25),
    marginBottom: 10,
    marginTop:10

  },
  categoryTitle: {
    flex: 4,
    //justifyContent:'flex-start',
    justifyContent: 'center',
    marginVertical: 5,

  },


  categoryTitleText: {
    fontSize: 20,
    color: colours.primaryGrey,
    marginTop: 2,
  },

  categorySeeAll: {
    justifyContent: 'flex-end',
    borderWidth: 0.5,
    paddingHorizontal: 15,
    paddingBottom: 10,
    borderRadius: 10,
    backgroundColor: colours.brandColorExtraDark,
  },
  categorySeeAllText: {
    fontSize: 12,
    fontWeight: '600',
    color: colours.brandColorLight
  },
  footer: {
    fontSize: 20,
    color: colours.primaryGrey,
    marginVertical: 30,

  },
  footerText: {
    fontSize: 10,
    color: colours.fifthGrey,
    textAlign: 'center',
    fontWeight: '400'
  },




  yearBoxContainer:
  {
    //marginHorizontal: 10,
    marginVertical: 20,
    paddingVertical: 20,
  },
  yearBoxRow: {
    marginVertical: 10,
    height:55,
    flexDirection: 'row',
    justifyContent: 'center',

  },
  yearBox1: {
    flex: 4,
    paddingHorizontal:15,
    borderRadius: 15,
    justifyContent: 'center',
    marginHorizontal: 10,
    marginVertical: 5,
    backgroundColor: '#3B5998',
  },
  yearBox2: {
    flex: 4,
    paddingHorizontal:15,
    borderRadius: 15,
    justifyContent: 'center',
    marginHorizontal: 10,
    marginVertical: 5,
    backgroundColor: '#0e76a8',
  },
  yearBox3: {
    flex: 4,
    paddingHorizontal:15,
    borderRadius: 15,
    justifyContent: 'center',
    marginHorizontal: 10,
    marginVertical: 5,
    backgroundColor: '#1DA1F2',
  },
  yearBox4: {
    flex: 4,
    paddingHorizontal:15,
    borderRadius: 15,
    justifyContent: 'center',
    marginHorizontal: 10,
    marginVertical: 5,
    backgroundColor: '#3f729b',
  },
  yearBox5: {
    flex: 4,
    paddingHorizontal:15,
    borderRadius: 15,
    justifyContent: 'center',
    marginHorizontal: 10,
    marginVertical: 5,
    backgroundColor: '#4fce5d',
  },
  yearBox6: {
    flex: 4,
    paddingHorizontal:15,
    borderRadius: 15,
    justifyContent: 'center',
    marginHorizontal: 10,
    marginVertical: 5,
    backgroundColor: colours.brandColorExtraDark,
  },

  yearBoxText: {
    fontSize: 12,
    color: 'white',
    textAlign: 'center',
    fontWeight: '500'

  },
  yearBoxText2: {
    fontSize: 14,
    color: 'white',
    textAlign: 'center',
    fontWeight: '500'

  },
  yearBoxTextHeader: {
    fontSize: 20,
    color: colours.thirdGrey,
    textAlign: 'center',
    fontWeight: '700'
  },



});
  

export default Footer;