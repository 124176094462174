

import React from "react";
import { View, Text, Dimensions, StyleSheet,TouchableOpacity,Linking } from "react-native";
import colours from '../config/colours'
const { height, width } = Dimensions.get('window')
if(height<width){
    var widthNew=height;
    var heightNew=width;
  } else{
    var widthNew=width;
    var heightNew=height;
  }


const Contact = ({
    navigation, route, hashtags, onPressHandle, thumbURL, imgURL, toggleModal
}) => (

    <View style={styles.modalContainer}>

<Text style={styles.textHeader}>CONTACT US</Text>
            <Text style={styles.textSubHeader}>Get In Touch</Text>


            <View style={styles.textContainer}>
            <Text style={styles.textSubHeader}>Drop a line if you need help at</Text>
            <Text style={styles.textEmail}>info@pdcgroupconsulting.com</Text>

            <Text style={styles.text}>we will respond you as soon as possible</Text>

            </View>


        <View style={{flexDirection: 'row', alignItems: 'center', marginBottom:15, marginTop:50}}>
          <View style={{flex: 1, height: 1, backgroundColor: colours.thirdGrey}} />
          <View>
            <Text style={{ textAlign: 'center', color:colours.thirdGrey}}>OR Click below to Send Us Email</Text>
          </View>
          <View style={{flex: 1, height: 1, backgroundColor: colours.thirdGrey}} />
        </View>




        <View style={styles.emailButtonContainer}>

            <TouchableOpacity 
				      onPress={() => Linking.openURL('mailto:info@pdcgroupconsulting.com?subject=Support&body=Hi') }
                >
             <View style={styles.emailContainer}>
					  <Text style={styles.emailText}>Email Us</Text>
            </View>
            </TouchableOpacity>

        </View>

                <View style={styles.closeButtonContainer}>
                    <TouchableOpacity
                    onPress={toggleModal} 
                    >
                        <View style={styles.closeContainer}>
                            <Text style={styles.closeText}>X</Text>
                        </View>
                    </TouchableOpacity>
                </View>

            </View>

    );

export default Contact;

const styles = StyleSheet.create({

  modalContainer:{
    flex:1,
   // marginHorizontal:widthNew*.05, 
   // marginVertical:heightNew*.05,
    borderRadius:25,
    backgroundColor:colours.primaryBlack,      
    alignContent:'center',
    alignItems:'center',
    justifyContent:'center',
    paddingBottom:40,
  },

  textHeader: {
    marginVertical:20,
    marginHorizontal:10,
    color:colours.fifthGrey,
    fontSize:30,
    fontWeight:'700'
    },    
    textSubHeader: {
      marginVertical:5,
      marginHorizontal:20,
      color:colours.thirdGrey,
      fontSize:15,
      fontWeight:'700'
      },

      textContainer: {
        marginTop:10,
        marginHorizontal:10,
        padding:10,
        marginBottom:10,
        backgroundColor: colours.tertiaryBlack,
        borderRadius:25,
        //height:height/3,
        justifyContent:'center',
        alignItems:'center',
        },
  

      textEmail: {
        marginVertical:5,
        marginHorizontal:20,
        color:colours.primaryGrey,
        fontSize:12,
        },
    text: {
      marginVertical:5,
      marginHorizontal:20,
      color:'grey',
      fontSize:12,
      },

      emailButtonContainer: {
        flex:1,
        alignItems:'center',
        marginVertical:10,
      },

      emailContainer: {
        backgroundColor: colours.brandColorExtraDark,
        alignItems:'center',
        borderRadius:20,
        paddingHorizontal:50,
        paddingVertical:20,
      },

    emailText: {
        fontSize: 20,
        color: colours.brandColorLight,
      },
    categoryTitleText: {
        fontSize: 20,
        color: colours.primaryGrey,
        marginTop:2,
        //paddingTop:10,
      },




      closeContainer: {
        backgroundColor: 'red',
        alignItems:'center',
        borderRadius:20,
        paddingHorizontal:20,
        paddingVertical:5,
      },
      closeButtonContainer: {
        flex:1,
        alignItems:'center',
        marginVertical:10,
      },
      closeText: {
        fontSize: 35,
        color: colours.primaryWhite,
      },

    
        

});


