

import React from "react";
import { View, Text,Image, Dimensions, StyleSheet,TouchableOpacity,Linking } from "react-native";
import colours from '../config/colours'
import basephotos from '../config/photos'


const { height, width } = Dimensions.get('window')
if(height<width){
    var widthNew=height;
    var heightNew=width;
  } else{
    var widthNew=width;
    var heightNew=height;
  }


const Pdcconsult = ({
    navigation, route, hashtags, onPressHandle, thumbURL, imgURL, toggleModal
}) => (

    <View style={styles.modalContainer}>



            
            <Image 
                  source={{uri: basephotos.pdcconsult}}
                  style={styles.logo} 
                />

          
            



        <View style={{flexDirection: 'row', alignItems: 'center', marginBottom:15, marginTop:50}}>
          <View style={{flex: 1, height: 1, backgroundColor: colours.thirdGrey}} />
          <View>
            <Text style={{ textAlign: 'center', color:colours.thirdGrey}}>Looking for<Text style={styles.textSubHeader}>PDC CONSULT ?</Text></Text>
          </View>
          <View style={{flex: 1, height: 1, backgroundColor: colours.thirdGrey}} />
        </View>




        <View style={styles.emailButtonContainer}>

            <TouchableOpacity 
				      onPress={() => Linking.openURL('https://pdcconsult.com') }
                >
             <View style={styles.emailContainer}>
					  <Text style={styles.emailText}>Go to pdccconsult.com</Text>
            </View>
            </TouchableOpacity>

        </View>

                <View style={styles.closeButtonContainer}>
                    <TouchableOpacity
                    onPress={toggleModal} 
                    >
                        <View style={styles.closeContainer}>
                            <Text style={styles.closeText}>X</Text>
                        </View>
                    </TouchableOpacity>
                </View>

            </View>

    );

export default Pdcconsult;

const styles = StyleSheet.create({

  modalContainer:{
    flex:1,
   // marginHorizontal:widthNew*.05, 
   // marginVertical:heightNew*.05,
    borderRadius:25,
    backgroundColor:colours.primaryBlack,      
    alignContent:'center',
    alignItems:'center',
    justifyContent:'center',
    paddingBottom:40,
  },
  
    textSubHeader: {
      marginVertical:5,
      marginHorizontal:5,
      color:colours.thirdGrey,
      fontSize:15,
      fontWeight:'700'
      },

      textContainer: {
        marginTop:10,
        marginHorizontal:10,
        padding:10,

        },

        logo: {
          height: 100,
          width: 100,
          marginTop:20,
          //resizeMode: 'contain',
      
        },
  

      textEmail: {
        marginVertical:5,
        marginHorizontal:20,
        color:colours.primaryGrey,
        fontSize:12,
        },
    text: {
      marginVertical:5,
      marginHorizontal:20,
      color:'grey',
      fontSize:12,
      },

      emailButtonContainer: {
        flex:1,
        alignItems:'center',
        marginVertical:10,
      },

      emailContainer: {
        backgroundColor: colours.brandColorExtraDark,
        alignItems:'center',
        borderRadius:20,
        paddingHorizontal:50,
        paddingVertical:20,
      },

    emailText: {
        fontSize: 20,
        color: colours.brandColorLight,
      },
    categoryTitleText: {
        fontSize: 20,
        color: colours.primaryGrey,
        marginTop:2,
        //paddingTop:10,
      },




      closeContainer: {
        backgroundColor: colours.brandColor,
        alignItems:'center',
        borderRadius:20,
        paddingHorizontal:20,
        paddingVertical:5,
      },
      closeButtonContainer: {
        flex:1,
        alignItems:'center',
        marginVertical:10,
      },
      closeText: {
        fontSize: 35,
        color: colours.primaryWhite,
      },
    
        

});


