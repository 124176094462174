import React, { useState, useEffect } from 'react';
import { StyleSheet,View, Image, Text, TouchableOpacity, Dimensions,SafeAreaView,Linking } from 'react-native';

import colours from '../config/colours';
import Header from '../components/header'
import Footer from '../components/footer'
import basephotos from '../config/photos'
import { useNavigation } from '@react-navigation/native';
import Modal from 'react-native-modal';
import Pdcconsult from '../components/pdcconsult'
import Pdcinteriors from '../components/pdcinteriors'
import Proconsult from '../components/velocitia'
import Vipre from '../components/made'
import LogoPDCC from '../assets/images/pdcc.png'
import LogoPDCI from '../assets/images/pdci.png'
import LogoVELOCITIA from '../assets/images/velocitia.png'
import LogoMADE from '../assets/images/made.png'

import { firebase } from '../config/firebase'
import 'firebase/analytics'


const { height, width } = Dimensions.get('window')

if(height<width){
  var widthNew=height;
  var heightNew=width;
} else{
  var widthNew=width;
  var heightNew=height;
}


const HomeScreen = () => {

  const [isModalVisiblePDCC, setModalVisiblePDCC] = useState(false);
  const [isModalVisiblePDCI, setModalVisiblePDCI] = useState(false);
  const [isModalVisibleVelocitia, setModalVisibleVelocitia] = useState(false);
  const [isModalVisibleMADE, setModalVisibleMADE] = useState(false);


  useEffect(() => {
    firebase.analytics();
// eslint-disable-next-line
}, []);

  const toggleModalPDCC = () => {
    setModalVisiblePDCC(!isModalVisiblePDCC);
  };
  const toggleModalPDCI = () => {
    setModalVisiblePDCI(!isModalVisiblePDCI);
  };
  const toggleModalVelocitia = () => {
    setModalVisibleVelocitia(!isModalVisibleVelocitia);
  };
  const toggleModalMADE = () => {
    setModalVisibleMADE(!isModalVisibleMADE);
  };
    const navigation = useNavigation();

    return (
      <SafeAreaView>
      <View style={styles.safeView}>
      <View style={styles.responsiveWidth}>

          <Header />





          {height<width && (

            <View style={styles.yearBoxContainer}>
            <View style={styles.categoryTitle}>
                    <Text numberOfLines={1} style={styles.yearBoxTextHeader} >
                      Group Companies
                    </Text>
                </View>


                <View style={styles.yearBoxRow}>
                
                
                
                <TouchableOpacity 
                //onPress={() => Linking.openURL('https://pdcconsult.com')}
                onPress={() => toggleModalPDCC()}
                    >
                <View style={styles.yearBox}>
                <Image 
                  source={{uri: basephotos.pdcconsult}}
                  style={styles.logo} 
                />
                  <Text style={styles.yearBoxText}>PDC Consult</Text>
                </View>
                </TouchableOpacity>

                <TouchableOpacity 
                //onPress={() => Linking.openURL('https://pdc-interiors.com')}
                onPress={() => toggleModalPDCI()}

                    >
                <View style={styles.yearBox}>
                <Image 
                  source={{uri: basephotos.pdcinteriors}}
                  style={styles.logo} 
                />
                  <Text style={styles.yearBoxText}>PDC Interiors</Text>
                </View>
                </TouchableOpacity>
              

              <TouchableOpacity 
                onPress={() => toggleModalMADE()}


                    >
                <View style={styles.yearBox}>
                <Image 
                  source={{uri: LogoMADE}}
                  style={styles.logo} 
                />
                  <Text style={styles.yearBoxText}>MaDe Building Materials</Text>
                </View>
                </TouchableOpacity>

                <TouchableOpacity 
                //onPress={() => Linking.openURL('https://vip-propertiesuae.com')}
                onPress={() => toggleModalVelocitia()}


                    >
                <View style={styles.yearBox}>
                <Image 
                  source={{uri: LogoVELOCITIA}}
                  style={styles.logo} 
                />
                  <Text style={styles.yearBoxText}>Velocitia</Text>
                </View>
                </TouchableOpacity>

            </View>
            </View>
              )
            }










{height>width && (

<View style={styles.yearBoxContainer}>
<View style={styles.categoryTitle}>
        <Text numberOfLines={1} style={styles.yearBoxTextHeader} >
          Group Companies
        </Text>
    </View>


    <View style={styles.yearBoxRow}>
    <TouchableOpacity 
    onPress={() => toggleModalPDCC()}

    //onPress={() => Linking.openURL('https://pdcconsult.com')}
        >
    <View style={styles.yearBox}>
    <Image 
      source={{uri: basephotos.pdcconsult}}
      style={styles.logo} 
    />
      <Text style={styles.yearBoxText}>PDC Consult</Text>
    </View>
    </TouchableOpacity>

    <TouchableOpacity 
    //onPress={() => Linking.openURL('https://pdc-interiors.com')}
    onPress={() => toggleModalPDCI()}

     >
    <View style={styles.yearBox}>
    <Image 
      source={{uri: basephotos.pdcinteriors}}
      style={styles.logo} 
    />
      <Text style={styles.yearBoxText}>PDC Interiors</Text>
    </View>
    </TouchableOpacity>
  </View>
  

  <View style={styles.yearBoxRow}>
  <TouchableOpacity 
    //onPress={() => Linking.openURL('https://proconsultuae.com')}
    onPress={() => toggleModalVelocitia()}

        >
    <View style={styles.yearBox}>
    <Image 
      source={{uri: LogoVELOCITIA}}
      style={styles.logo} 
    />
      <Text style={styles.yearBoxText}>Velocitia</Text>
    </View>
    </TouchableOpacity>

    <TouchableOpacity 
    //onPress={() => Linking.openURL('https://vip-propertiesuae.com')}
    onPress={() => toggleModalMADE()}

        >
    <View style={styles.yearBox}>
    <Image 
      source={{uri: LogoMADE}}
      style={styles.logo} 
    />
      <Text style={styles.yearBoxText}>MaDe Building Materials</Text>
    </View>
    </TouchableOpacity>
  </View>

</View>
  )
}




              


            <View style={styles.collectionsContainer}>
              <View style={styles.categoryContainer}>
                <View style={styles.categoryTitle}>
                    <Text numberOfLines={1} style={styles.categoryTitleText} >
                     Chairman  
                    </Text>
                </View>

                <View style={styles.categorySeeAll}>
                <TouchableOpacity 
                  //onPress={() => navigation.navigate('SERVICES')} 
                  onPress={() => Linking.openURL('https://marwaabdelaziz.com')}


                    >
                    <Text numberOfLines={1} style={styles.categorySeeAllText} >
                      Learn More
                    </Text>
                </TouchableOpacity>
                </View>
              </View>
              





        {height>=width && (

                <View style={styles.videoBoxRow}>
                <View style={styles.videoBoxPortrait}>
                  <Text style={styles.videoBoxText}>Marwa Abdelaziz</Text>

                  <View style={styles.mdphotoPotraitContainer}>

                  <Image 
                  source={{uri: basephotos.mdphotodata}}
                  style={styles.mdphotoPotrait} 
                />     
                </View>



                <Text style={styles.DescriptionText}>
                  Marwa Abdelaziz is a pioneer and expert in developing projects 
                  to build revenue, profits and corporate visibility with 20 years of 
                  extensive experience in all facets of projects starting with site identification, 
                  developing and overseeing the implementation of the feasibility plans for projects; 
                  actively participating in the various financial analysis, acquisition, 
                  architectural and interior design review and tendering.{"\n"}{"\n"}

                  She is in the interior decoration industry since 2008, during which she has completed an impressive portfolio of residential, commercial and hospitality projects from conceptual phase till full turnkey handover. Held Senior positions in reputable firms like Al Rostamani and Noor Islamic Bank where she was working closely with top notch experts in the field.{"\n"}{"\n"}

                  Marwa holds an Architectural Engineering degree, PMP and Master in Project Management from the American Academy U.S.A.{"\n"}{"\n"}

                  To lear more please visit : <TouchableOpacity
                //onPress={() => navigation.navigate('PROJECTS')} 
                onPress={() => Linking.openURL('https://marwaabdelaziz.com')}
                >
                    <Text numberOfLines={1} style={styles.categorySeeAllText} >
                    www.MarwaAbdelaziz.com
                    </Text>
                    </TouchableOpacity>
                  </Text>  

                  
                </View>

                </View>
        )}






{height<width && (

                <View style={styles.videoBoxRow}>
                <View style={styles.videoBox}>
                  <Text style={styles.videoBoxText}>Marwa Abdelaziz</Text>


                  <Image 
                  source={{uri: basephotos.mdphotodata}}
                  style={styles.mdphoto} 
                />      



                  <Text style={styles.videoBoxText}></Text>
                  
                </View>



                <View style={styles.videoBox}>

                

                  <Text style={styles.DescriptionText}>
                  Marwa Abdelaziz is a pioneer and expert in developing projects 
                  to build revenue, profits and corporate visibility with 20 years of 
                  extensive experience in all facets of projects starting with site identification, 
                  developing and overseeing the implementation of the feasibility plans for projects; 
                  actively participating in the various financial analysis, acquisition, 
                  architectural and interior design review and tendering.{"\n"}{"\n"}

                  She is in the interior decoration industry since 2008, during which she has completed an impressive portfolio of residential, commercial and hospitality projects from conceptual phase till full turnkey handover. Held Senior positions in reputable firms like Al Rostamani and Noor Islamic Bank where she was working closely with top notch experts in the field.{"\n"}{"\n"}

                  Marwa holds an Architectural Engineering degree, PMP and Master in Project Management from the American Academy U.S.A.{"\n"}{"\n"}

                  To lear more please visit : <TouchableOpacity
                //onPress={() => navigation.navigate('PROJECTS')} 
                onPress={() => Linking.openURL('https://marwaabdelaziz.com')}
                >
                    <Text numberOfLines={1} style={styles.categorySeeAllText} >
                    www.MarwaAbdelaziz.com
                    </Text>
                    </TouchableOpacity>
                  </Text>            
                </View>

                </View>
)}










                </View>
              





<Footer />


<Modal 
isVisible={isModalVisiblePDCC} 
animationIn={'zoomIn'}
animationOut={"zoomOut"}

transparent={true}
onBackdropPress={toggleModalPDCC}
>
<View>
  <Pdcconsult
  toggleModal={toggleModalPDCC}
  />
</View>
</Modal>





<Modal 
isVisible={isModalVisiblePDCI} 
animationIn={'zoomIn'}
animationOut={"zoomOut"}

transparent={true}
onBackdropPress={toggleModalPDCI}
>
<View>
  <Pdcinteriors
  toggleModal={toggleModalPDCI}
  />
</View>
</Modal>







<Modal 
isVisible={isModalVisibleVelocitia} 
animationIn={'zoomIn'}
animationOut={"zoomOut"}

transparent={true}
onBackdropPress={toggleModalVelocitia}
>
<View>
  <Proconsult
  toggleModal={toggleModalVelocitia}
  />
</View>
</Modal>








<Modal 
isVisible={isModalVisibleMADE} 
animationIn={'zoomIn'}
animationOut={"zoomOut"}

transparent={true}
onBackdropPress={toggleModalMADE}
>
<View>
  <Vipre
  toggleModal={toggleModalMADE}
  />
</View>
</Modal>









      </View>
      </View>
      </SafeAreaView>

    );
  };



const styles = StyleSheet.create({
  safeView: {
    flex: 1,
    backgroundColor: colours.primaryBlack,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems:'center'
  },

  responsiveWidth: {
    //width:widthNew,
    width: width>height? (width *.70) : widthNew,
    alignContent: 'center',
    justifyContent: 'center',
    //alignItems:'center'
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 5,
    marginHorizontal: 5,
  },

  categoryTitle: {
    flex: 4,
    justifyContent: 'center',
    marginVertical: 5,
  },

  categorySeeAll: {
    justifyContent: 'flex-end',
    borderWidth: 0.5,
    paddingHorizontal: 15,
    paddingBottom: 10,
    borderRadius: 10,
    backgroundColor: colours.brandColorExtraDark,
  },
  categoryContainer: {
    flexDirection: 'row',
    marginHorizontal: (widthNew / 25),
    paddingBottom: 10,
    marginBottom: 10,
    borderBottomColor: colours.secondGrey,
    borderBottomWidth: 1,
  },

  categoryTitleText: {
    fontSize: 20,
    color: colours.primaryGrey,
    marginTop: 2,
  },
  
  categorySeeAllText: {
    fontSize: 12,
    fontWeight: '600',
    color: colours.brandColorLight
  },

  yearBoxContainer:
  {
    marginVertical: (widthNew / 5),
    paddingVertical: 20,



  },
  yearBoxRow: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginVertical:20,
    //height: (widthNew / 3.5),
  },
  yearBox: {
    flex: 2,
    width: width>height? (widthNew / 3.5) : (widthNew /3),
    //height: (widthNew / 5),
    borderRadius: 15,
    alignItems:'center',
    justifyContent: 'center',
    marginHorizontal: 5,
    marginVertical: 5,
    paddingVertical:20,
    paddingHorizontal:10,
    backgroundColor: colours.secondaryBlack,
  },
  DescriptionText: {
    fontSize: 16,
    color: colours.forthGrey,
    textAlign: 'justify',
    fontWeight: '400',
    padding:15
  },

  logo: {
    height: 100,
    width: 100,
    //marginBottom:10,
    resizeMode: 'contain',

  },
  mdphoto: {
    height: '80%',
    width: '80%',
    marginVertical:10,
    resizeMode: 'contain',
    alignSelf:'center'

  },

  mdphotoPotrait: {
    height: '100%',
    width: '100%',
    marginBottom:10,
    resizeMode:'contain',
    alignSelf:'center',

  },
  mdphotoPotraitContainer: {
    height:width/2,
    width: width/2,
    marginVertical:10,
    //resizeMode: 'contain',
    alignSelf:'center',

  },

  yearBoxText: {
    fontSize: 12,
    textAlign: 'center',
    fontWeight: '600',
    color: colours.forthGrey,
    marginTop:25,
  },
  yearBoxTextHeader: {
    fontSize: 16,
    color: colours.thirdGrey,
    textAlign: 'center',
    fontWeight: '500'
  },

  collectionsContainer: {
    marginHorizontal: 10,
    backgroundColor: colours.firstGrey,
    borderRadius: 20,
    marginVertical: 10,
    paddingVertical: 10,
  },


  videoBoxRow: {
    margin: 5,
    flexDirection: 'row',
    justifyContent: 'center',

  },

  videoBox: {
    flex: 1,
    width: (widthNew) * .75,
    paddingVertical: 15,
    borderRadius: 15,
    justifyContent: 'center',
    backgroundColor: colours.primaryBlack,
    borderColor: colours.highlightBlack,
    borderWidth: 2,
  },
  videoBoxPortrait: {
    flex: 1,
    width: (widthNew) * .75,
    paddingVertical: 15,
    borderRadius: 15,
    justifyContent: 'center',
    backgroundColor: colours.primaryBlack,
    borderColor: colours.highlightBlack,
    borderWidth: 2,
    //height:height+10,
  },
  videoBoxText: {
    fontSize: 17,
    paddingVertical:8,
    color: colours.forthGrey,
    textAlign: 'center',
    fontWeight: '600',
    borderBottomColor:colours.firstGrey,
    borderBottomWidth:.5,
  },


});
  

export default HomeScreen;
  
  


