// Colours
const colours = {
  brandColor:'#bd0000',
  brandColorLight:'#d43c3c',
  brandColorDark: '#8b0000',

  brandColorExtraDark: '#3e0007',


  primaryBlack: '#101010',
  secondaryBlack: '#1D1D1D',
  tertiaryBlack: '#141414',
  highlightBlack: '#0B0B0B',
  primaryWhite: '#fff',
  primaryTeal: '#07CCBA',
  primaryGrey: '#AAAAAA',
  //secondaryGrey: '#3E3E3E', 
  secondaryGrey:'#4d4d4d',
  firstGrey: '#212121',
 // secondGrey: '#424242',
  secondGrey: '#333333',
  thirdGrey: '#616161',
  forthGrey: '#757575',
  fifthGrey:'#9e9e9e',
  //New Set

};
export default colours;
