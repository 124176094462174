
import 'react-native-gesture-handler';import React from 'react';

import { StyleSheet,Platform,View,Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl,ScrollView } from 'react-native';


import { NavigationContainer, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { HeaderBackButton } from '@react-navigation/stack';

import colours from '../config/colours';

import HomeScreen from './homeScreen' 




const HomeStack = createStackNavigator();
function HomeStackScreen({route, navigation}) {


  return (
    <HomeStack.Navigator screenOptions={{ headerShown: false}}>
      <HomeStack.Screen name='HOME' component={HomeScreen} options={{title:'PDC Group'}}/>

    </HomeStack.Navigator>
  );
}

const linking = {
  prefixes: ['localhost:3000/','https://pdcgroupconsulting.web.app/'],
  config: {
    screens: {
      HOME: '',
    }
  },
};


function HomeNavigator() {
  
  return (
    <View style={styles.navContainer}>

    <NavigationContainer linking={linking} theme={DarkTheme}>
      <HomeStackScreen />
    </NavigationContainer>
    </View>
  )
}

export default HomeNavigator;
const styles = StyleSheet.create({ 
  navContainer: { 
    ...Platform.select({ 
      native: { flex: 1, }, 
      web: { height: '100vh', }, 
    }), 
    }
  })