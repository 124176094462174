//import * as firebase from 'firebase';
import firebase from 'firebase/app'
import '@firebase/auth';
import '@firebase/firestore';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCFrbrf84f0O5J7WeGC-YDk8W7uqc8Fd-k",
  authDomain: "pdc-group.firebaseapp.com",
  databaseURL: "https://pdc-group.firebaseio.com",
  projectId: "pdc-group",
  storageBucket: "pdc-group.appspot.com",
  messagingSenderId: "670821238326",
  appId: "1:670821238326:web:dd1e110f7cf6293a027a97",
  measurementId: "G-B2GZ7HNPM2"
};


if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export { firebase };